import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import styles from './PrivateAuth.module.scss'

const classes = {
  container: [
    'w-full',
    'max-w-xs',
    'mr-auto',
    'ml-auto',
    'text-center',
    //
  ].join(' '),
  label: [
    'block',
    'font-sans-display',
    'text-red-100',
    'text-lg',
    'sm:text-xl',
    'text-center',
    //
  ].join(' '),
  input: [
    'mt-4',
    'px-3',
    'py-2',
    'block',
    'w-full',
    'text-center',
    'text-2xl',
    'leading-none',
    'bg-gray-200',
    'border-solid',
    'border',
    'border-red-100',
    'focus:outline-none',
    //
  ].join(' '),
  submitContainer: [
    'mt-4',
    'flex',
    'justify-center',
    //
  ].join(' '),
  submit: [
    'relative',
    'inline-block',
    'font-sans-display',
    'font-light',
    'text-red-100',
    'text-2xl',
    'md:text-3xl',
    'focus:outline-none',
    //
  ].join(' '),
  arrow: [
    'font-serif-display',
    'text-red-100',
    'text-2xl',
    'md:text-3xl',
    //
  ].join(' '),
}

class PrivateAuth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault()

    const { password, doAuth } = this.props
    const { value } = this.state

    if (password === value) doAuth()
  }

  render() {
    return (
      <div className={classes.container}>
        <form onSubmit={this.handleSubmit}>
          <label className={classes.label}>
            <Translate id='password' />
            <input
              type='password'
              value={this.state.value}
              onChange={this.handleChange}
              className={classes.input}
              placeholder='•••••'
            />
          </label>
          <div className={classes.submitContainer}>
            <span className={[classes.arrow, styles.arrow].join(' ')}>→</span>
            <button
              type='submit'
              className={[classes.submit, styles.submit].join(' ')}
            >
              <span>
                <Translate id='confirm' />
              </span>
            </button>
          </div>
        </form>
      </div>
    )
  }
}

PrivateAuth.propTypes = {
  password: PropTypes.string.isRequired,
  doAuth: PropTypes.func.isRequired,
}

export default PrivateAuth
