import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import fitty from 'fitty'
import { PhotoSwipe } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import screenfull from 'screenfull'
import onPageRender from '../hocs/onPageRender'
import { setHeaderTheme } from '../actions/headerTheme'
import LinkedProjectsGrid, {
  LinkedProjectsColumn as Col,
} from '../components/LinkedProjectsGrid/LinkedProjectsGrid'
import StandaloneImage from '../components/StandaloneImage/StandaloneImage'
import Portal from '../components/Portal/Portal'
import { Translate } from 'react-localize-redux'
import Footer from '../components/Footer/Footer'
import PrivateAuth from '../components/PrivateAuth/PrivateAuth'
import Head from '../components/Head/Head'

const classes = {
  container: [
    'relative',
    'py-32',
    'min-h-screen',
    //
  ].join(' '),
  auth: [
    'absolute',
    'inset-0',
    'flex',
    'flex-col',
    'justify-center',
    'px-6',
    //
  ].join(' '),
  title: [
    'mb-6',
    'text-center',
    'font-serif-display',
    'text-3xl',
    'xl:text-7xl',
    'uppercase',
    'leading-tight',
    'tracking-tight',
    'text-red-100',
    //
  ].join(' '),
}

class Private extends Component {
  constructor(props) {
    super(props)
    this.state = {
      auth: false,
      pswpOpen: false,
      pswpIndex: 0,
    }
    this.doAuth = this.doAuth.bind(this)
    this.showPswp = this.showPswp.bind(this)
    this.hidePswp = this.hidePswp.bind(this)
    this.pswpData = props.data.page.data.body.map(({ primary }, index) => {
      const { image } = primary
      const { url, dimensions } = image
      const { width, height } = dimensions
      return {
        index,
        src: url,
        w: width,
        h: height,
      }
    })
  }

  componentDidMount() {
    const { setHeaderTheme } = this.props
    setHeaderTheme('grayWithBg')
    fitty(this.title)
  }

  doAuth() {
    this.setState({ auth: true })
  }

  showPswp(pswpIndex) {
    if (screenfull.enabled) {
      screenfull.request().then(() => {
        this.setState({ pswpIndex, pswpOpen: true })
      })
    }
  }

  hidePswp() {
    this.setState({ pswpOpen: false })
  }

  render() {
    const { data, pageContext } = this.props
    const { lang } = pageContext
    const { password, body, title } = data.page.data
    const { pagePath } = data.page.fields
    const { auth, pswpOpen, pswpIndex } = this.state
    return (
      <div className={classes.container}>
        <Head title={title.text} path={pagePath} />
        {!auth && (
          <div className={classes.auth}>
            <div
              className='text-center mr-auto ml-auto w-full'
              style={{ maxWidth: '600px' }}
            >
              <div className={classes.title} ref={el => (this.title = el)}>
                <Translate id='privateArea' />
              </div>
            </div>
            <PrivateAuth password={password} doAuth={this.doAuth} />
            <div className='pt-24' />
          </div>
        )}
        {auth && (
          <>
            <LinkedProjectsGrid>
              {body.map(({ id, primary }, i) => {
                const { image } = primary
                return (
                  <Col key={id}>
                    <StandaloneImage
                      image={image}
                      onClick={() => this.showPswp(i)}
                      id={`privateImage${i}`}
                      index={i + 1}
                    />
                  </Col>
                )
              })}
            </LinkedProjectsGrid>
            <Portal>
              <PhotoSwipe
                isOpen={pswpOpen}
                items={this.pswpData}
                onClose={this.hidePswp}
                options={{
                  index: pswpIndex,
                  showHideOpacity: true,
                  history: false,
                  fullscreenEl: true,
                  zoomEl: false,
                  shareEl: false,
                  preloaderEl: false,
                  barsSize: { top: 65, bottom: 60 },
                  getThumbBoundsFn: index => {
                    const thumbnail = document.getElementById(
                      `privateImage${index}`
                    )

                    if (thumbnail) {
                      const pageYScroll =
                        window.pageYOffset || document.documentElement.scrollTop
                      const rect = thumbnail.getBoundingClientRect()
                      return {
                        x: rect.left,
                        y: rect.top + pageYScroll,
                        w: rect.width,
                      }
                    }
                  },
                }}
              />
            </Portal>
          </>
        )}
        <Footer theme='gray' lang={lang} />
      </div>
    )
  }
}

Private.propTypes = {
  data: PropTypes.object.isRequired,
  setHeaderTheme: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTheme: theme => dispatch(setHeaderTheme(theme)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(onPageRender(Private))

export const query = graphql`
  query($id: String!) {
    page: prismicPrivat(id: { eq: $id }) {
      data {
        title {
          text
        }
        password
        body {
          ... on PrismicPrivatBodyFoto {
            id
            primary {
              image {
                ...ImageData
              }
            }
          }
        }
      }
      fields {
        pagePath
      }
    }
  }
`
